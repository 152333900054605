/* Copyright 2022- Martin Kufner */
import {QbInputElement} from "./qb-element.js";

export class QBTagsContainer extends QbInputElement {
    static _datalist = document.createElement('datalist');
    static shadow = 'open'

    static tags(...tags) {
        tags.forEach(tag => {
            if(this._datalist.querySelector(`[value="${tag}"]`)) return;
            const option = document.createElement('option');
            option.value = tag;
            this._datalist.append(option);
        });
    }

    static get datalist() {
        const datalist = this._datalist;
        datalist.id = "tag-list";
        return datalist;
    }

    constructor() {
        super();
        this.newNode = cT('input type="text" list="tag-list" pattern=".+" placeholder="+ Tag"', {
            beforeend: this.contentRoot,
            events: [this, "input change focus blur"]
        });
    }

    handleEvent_input(evt) {
        const target = evt.currentTarget;
        target.style.width = ((target.value.length + 2) * 0.8) + 'ch';
    }

    handleEvent_focus() {
        this.contentRoot.append(QBTagsContainer.datalist)
    }

    handleEvent_blur(evt) {
        const target = evt.currentTarget;
        target.style.width = "";
        if(!target.value) return QBTagsContainer.datalist.remove();
        target.focus();
    }


    get nodes() { return Array.from(this.contentRoot.querySelectorAll('div[data-kind]')) }

    get value() {
        const values = this.nodes.filter(n => !n.dataset.kind.startsWith("_"))
            .map(n => [n.dataset.kind, n.firstElementChild.firstElementChild.checked && n.firstElementChild.firstElementChild.value]);
        return Object.fromEntries(values);
    }

    set value(value) {
        if(!value) value = {};
        this.nodes.forEach(n => n.remove());
        this.add(...Object.entries(value).map(e => Object.fromEntries([e])));
    }

    add(...values) {
        const insert = (value, kind) => {
            if(!kind) kind = value.toLowerCase();
            if(this.contentRoot.querySelector(`[data-kind="${kind}"]`)) return;
            if(!kind.startsWith('_')) QBTagsContainer.tags(value);
            DIV({dataset: {kind}, beforebegin: this.newNode},
                LABEL(
                    cT('input tabindex="-1" type="checkbox" checked="true"', {
                        value, events: [this, {change: "remove"}]
                    })
                ),
                SPAN(value)
            );
        }
        values.forEach(value => {
            if(typeof value === 'string') {
                if(/^\{.*\}$/.test(value)) {
                    try { value = JSON.parse(value) } catch(e) { return }
                }
                else return insert(value);
            }
            if(Object.type(value) === 'Object') Object.entries(value).forEach(([k, v]) => insert(v, k));
        });
    }

    handleEvent_change(evt) {
        evt.stopImmediatePropagation();
        const input = evt.currentTarget,
            value = input.value.trim().replace(/^_/, "");
        if(/^\*$/.test(value)) return;
        this.add(value);
        setTimeout(() => input.value = "");
        this.dispatch("change", {value: this.value});
    }

    handleEvent_remove(evt) {
        evt.stopImmediatePropagation();
        // const target = evt.currentTarget;
        // target.disabled = true;
        // console.log(target.parentElement)
        // target.parentElement.remove();
        this.dispatch("change", {value: this.value});
    }

    handleEvent_cancel(evt) {
        const target = evt.currentTarget,
            targetWidth = target.getBoundingClientRect().width,
            targetlastElementWidth = target.lastElementChild?.getBoundingClientRect()?.width || 0;
        if(target.nodeName !== "SPAN" && evt.offsetX < targetWidth - targetlastElementWidth) return;
        setTimeout(() => this.cancelled = true, 10);
    }
}

window.customElements.define('qb-tags', QBTagsContainer);