/* Copyright 2022- Martin Kufner */

const tagNames = "A ABBR ACRONYM ADDRESS APPLET AREA ARTICLE ASIDE AUDIO B BASE BASEFONT BDI BDO BIG BLOCKQUOTE BODY BR BUTTON CANVAS CAPTION CENTER CITE CODE COL COLGROUP DATA DATALIST DD DEL DETAILS DFN DIALOG DIR DIV DL DT EM EMBED FIELDSET FIGCAPTION FIGURE FONT FOOTER FORM FRAME FRAMESET HEAD HEADER HGROUP H1 HR HTML I IFRAME IMG INPUT INS KBD KEYGEN LABEL LEGEND LI LINK MAIN MAP MARK MENU MENUITEM META METER NAV NOFRAMES NOSCRIPT OBJECT OL OPTGROUP OPTION OUTPUT P PARAM PICTURE PRE PROGRESS Q RP RT RUBY S SAMP SCRIPT SECTION SELECT SMALL SOURCE SPAN STRIKE STRONG STYLE SUB SUMMARY SUP SVG TABLE TBODY TD TEMPLATE TEXTAREA TFOOT TH THEAD TIME TITLE TR TRACK TT U UL VAR VIDEO WBR".split(/\s+/).filter(t => t);

const AppendableObjectRE = /^(Text|DocumentFragment|CDATASection|String)$/;
const AppendableRE = /^(string|number|boolean)$/;

const setAttributes = function (attrs) {
    for(const key in attrs) {
        if(!Object.hasOwn(attrs, key)) continue;
        const value = attrs[key];
        if(value === null || value === undefined) this.removeAttribute(key);
        else this.setAttribute(key, value);
    }
}
const setCustomProperties = function (key_object, value) {
    if(value !== undefined) {
        if(typeof key_object !== "string") throw 'key must be a string iv value exists';
        key_object = Object.fromEntries([[key_object, value]]);
    }
    for(let [k, v] of Object.entries(key_object)) {
        k = k.replace(/^-*/, "--");
        if(v instanceof Text) v = `"${v.data}"`;
        if(v || v !== 0) this.style.setProperty(k, v);
        else this.style.removeProperty(k);
    }
}
const setObserver = function (value, target = this.parentElement) {
    if(value[0] instanceof HTMLElement) target = value.shift();
    let [callback, options] = value;
    if(!callback) callback = target.observer
    else if(typeof callback === "string") callback = target[`observer_${callback}`];
    if(typeof callback == "function") {
        const observer = new MutationObserver((m, o) => callback.call(target, m, o));
        observer.observe(this, options || { childList: true });
        return observer;
    }

    const klass = target.constructor.name;
    let msg = `cT Observer: handler not found in ${klass}: observer(mutation){}`;
    if(value[0]) msg += `observer_${value[0]}(mutation){}`;
    const exc = Object.assign(new Error(msg), {node: this, target});
    Reflect.defineProperty(exc, "stack", {
        value: exc.stack.replace(/\n\s+at.*?at cT[^\n]+/s, '')
    })
    console.info(exc);

}

const setIntersection = function (value, target = this.parentElement) {

}

const setEvents = function (value, target = this.parentElement) {
    if(typeof value === "string") {
        try {
            JSON.parse(value)
        }
        catch(e) {
            value = JSON.stringify([value]);
        }
        this.setAttribute("events", value);
        return;
    }
    const events = [];
    if(value instanceof Array) for(const v of value) {
        if(v instanceof Array) events.push(v.slice(0, 2));
        else if(v instanceof HTMLElement) target = v;
        else if(typeof v === "object") events.push(...Object.entries(v));
        else if(typeof v === "string") events.push(...v.split(/\s+/).map(v => [v]));
    }
    else if(typeof value === "object") {
        const {target: _target, ...values} = value;
        if(_target) target = _target;
        events.push(...Object.entries(values))
    }
    for(const [e, fn] of events) {
        let method;
        if(typeof fn === "function") method = fn.bind(target);
        else {
            const name = fn || cT.sanitizeName(this.getAttribute("name"));
            method = (name && (target[`handleEvent_${name}_${e}`] || target[`handleEvent_${name}`])) || target[`handleEvent_${e}`];
            if(!method && target.handleEvent) method = target;
            else if(!method) {
                const klass = target.constructor.name;
                let msg = `cT Event: handler for ${e} not found in ${klass}:`;
                if(name) msg += `handleEvent_${name}_${e}(evt){} or handleEvent_${name}(evt){} or `;
                msg += `handleEvent_${e}(evt) or handleEvent(evt){}`;
                const exc = Object.assign(new Error(msg), {node: this, target});
                Reflect.defineProperty(exc, "stack", {
                    value: exc.stack.replace(/\n\s+at.*?at cT[^\n]+/s, '')
                })
                console.info(exc);
                continue;
            }
        }
        this.addEventListener(e, method.bind(target), {capture: true});
    }
}

const synonyms = {
    html: "innerHTML",
    maxlength: 'maxLength'
}


export const cT = function (node, ...args) {
    if(typeof node === 'string') {
        if(/[<\s.]/.test(node)) {
            const frag = document.createElement('div');
            const [_, nodeName, attrs, innerHTML] = node.match(/^(\S+)\s?([^<]+)?(.*)$/);
            if(/\./.test(nodeName)) {
                const [_nodeName, ...classNames] = nodeName.trim().split('.')
                frag.innerHTML = `<${_nodeName} ${attrs || ''}>${innerHTML || ''}`;
                node = frag.firstElementChild;
                node.classList.add(...classNames);
            }
            else {
                frag.innerHTML = `<${nodeName} ${attrs}>${innerHTML}`;
                node = frag.firstElementChild;
            }
        }
        else node = document.createElement(node);
    }
    if(!args.length) return node;
    const events = [], observe = [], intersect = [];
    let target, findBy, connect;
    for(const arg of args.flat()) {
        if(arg === null || arg === undefined) continue;
        else if(typeof arg === 'object') {
            if(arg instanceof HTMLElement || AppendableObjectRE.test(args.constructor.name)) node.append(arg);
            else {
                for(let k in arg) {
                    if(k in synonyms) k = synonyms[k];
                    switch(k) {
                        case "findBy":
                            findBy = arg.findBy;
                            continue;
                        case "data":
                        case "dataset":
                            Object.assign(node.dataset, arg[k]);
                            continue;
                        case 'intersect':
                            intersect.push(arg.intersect);
                            continue;
                        case 'observe':
                            observe.push(arg.observe);
                            continue;
                        case 'events':
                            events.push(arg.events);
                            continue;
                        case 'class':
                        case 'className':
                            const classNames = arg[k]?.split(/\s+/).filter(n=>n);
                            if(classNames?.length) node.classList.add(...classNames);
                            continue;
                        case 'classList':
                            node.classList.add(...arg.classList);
                            continue;
                        case 'itemid':
                        case 'itemtype':
                            node.setAttribute('itemscope', '');
                        case '_content':
                        case '_title':
                        case '_placeholder':
                        case 'for':
                        case 'form':
                        case 'required':
                        case 'pattern':
                            node.setAttribute(k, arg[k]);
                            continue;
                        case 'attributes':
                            setAttributes.call(node, arg.attributes);
                            continue;
                        case 'customProperties':
                            setCustomProperties.call(node, arg.customProperties);
                            continue;
                        case 'afterbegin':
                        case 'beforeend':
                            if(arg[k] instanceof ShadowRoot) {
                                target = arg[k].host;
                                connect = [k, arg[k]];
                                continue;
                            }
                        case 'afterend':
                        case 'beforebegin':
                            connect = [k, target = arg[k]];
                            continue;
                        case 'replace':
                            arg.replace.replaceWith(node);
                            continue;
                    }
                    if(node.__lookupSetter__(k)) node[k] = arg[k];
                    else if(!node.__lookupGetter__(k) && typeof node[k] === 'function') node[k][arg[k] instanceof Array ? 'apply' : 'call'](node, arg[k]);
                    else console.trace(`cT warning: not found '${k}' in`, node, args);
                }
            }
        }
        else if(AppendableRE.test(typeof arg)) node.append(arg.toString());
        else throw `cT warning: Can't add ${typeof arg}`;
    }
    if(connect && connect[1]) {
        const [position, ref] = connect;
        if(findBy) {
            let n;
            switch(position) {
                case 'afterbegin':
                case 'beforeend':
                    n = ref.querySelector(findBy);
                    if(n) return n;
                    break;
                default:
                    n = k === 'beforebegin' ? ref.previousElementSibling : ref.nextElementSibling;
                    if(n?.matches(findBy)) return n;
                    break;
            }
        }
        if(position === 'afterbegin') ref.prepend(node);
        else if(position === 'beforeend') ref.append(node);
        else ref.insertAdjacentElement(position, node);
    }
    for(const e of events) setEvents.call(node, e, target);
    for(const o of observe) setObserver.call(node, o, target);
    for(const i of intersect) setIntersection.call(node, i, target);
    return node;
}

cT.register = function(options) {
    let {attach, prefix, postfix, overwrite, shortcuts, tags} = Object.assign({
        attach: self,
        prefix: '',
        postfix: '',
        overwrite: false,
        shortcuts: true,
        tags: true
    }, options);
    Reflect.defineProperty(attach, 'cT', {value: cT});
    if(tags) {
        const convert = tags === "downcase" ? "toLowerCase" : "toUpperCase";
        tagNames.forEach(tagName => {
            tagName = tagName[convert]();
            const regName = `${prefix}${tagName}${postfix}`;
            if(regName in attach && !overwrite) console.warn(`Not registering ${regName} exists:`, attach[regName]);
            else Reflect.defineProperty(attach, regName, {value: cT.bind(null, tagName)});
        });
    }
    if(shortcuts) import("./shortcuts.js");
}


Object.defineProperties(cT, {
    ...Object.fromEntries(tagNames.map(tag => [tag, {value: cT.bind(null, tag)}])),
    setEvents: {
        value(node, events) {
            setEvents.call(node, events);
        }
    },
    setAttributes: {
        value(node, events) {
            setAttributes.call(node, events);
        }
    },
    sanitizeName: {
        value(name) {
            return name?.replace(/(-(.))|(\[)|]/g, (...m) => m[3] ? "_" : m[2]?.toUpperCase() || "");
        }
    },
    setCustomProperties: {
        value(node, key_object, value) {
            setCustomProperties.call(node, key_object, value);
        }
    },
    sortIn: {
        value(container, node) {
            container.append(node);
            this.sort(container);
            return node;
        }
    },
    sort: {
        value(container, sort = 'sortIndex') {
            if(container.sortingInProgress) return container;
            const children = container.children;
            window.requestAnimationFrame(() => {
                const sorted = [...children].sort((lo, hi) => typeof sort === 'function' ? sort(lo) - sort(hi) : lo[sort] - hi[sort]);
                for(const i in sorted) {
                    const n = sorted[i], c = children[i];
                    if(c !== n) c.before(n);
                }
                delete container.sortingInProgress;
            });
            container.sortingInProgress = true;
            return container;
        }
    },
    Fragment: {
        value(...args) {
            const fragment = document.createDocumentFragment(),
                node = document.createElement('div');
            for(const arg in args) {
                if(typeof arg === 'string') {
                    node.innerHTML = arg;
                    fragment.append(node.firstElementChild);
                }
                else if(AppendableObjectRE.test(arg.constructor.name)) fragment.append(arg);
            }
            return fragment;
        }
    },
    eventTarget: {
        value(target, root) {
            console.trace('implement cT#event target', {target, root})
        }
    }
})



