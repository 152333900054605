(function () {
    if(Object.getOwnPropertyDescriptor(document, "readyStatePromise")) return;
    const t0 = new Date();
    Object.defineProperties(document, {
        "readyStatePromise": {
            get() {
                const resolve = {},
                    value = {
                        complete: new Promise(r => resolve.complete = r),
                        interactive: new Promise(r => resolve.interactive = r)
                    };
                Object.defineProperty(this, "readyStatePromise", {value, configurable: false, writable: false});

                const readyStateResolve = (state) => {
                    const execution_time = (new Date() - t0) / 1000;
                    switch(state) {
                        case "complete":
                            resolve.interactive(execution_time);
                            resolve.complete(execution_time);
                            return true;
                        case "interactive":
                            resolve.interactive(execution_time);
                    }
                }
                if(!readyStateResolve(this.readyState)) this.addEventListener('readystatechange', () => readyStateResolve(document.readyState));
                return value;
            },
            configurable: true
        },
        stateComplete: {
            get() { return this.readyStatePromise.complete; }
        },
        stateInteractive: {
            get() { return this.readyStatePromise.interactive; }
        }
    });
})();
import "~/qb-assets"
