/* Copyright 2022- Martin Kufner */
import {QbInputElement} from "./qb-element.js"

class QBCollaborativeTextinput extends QbInputElement {
    static nodeName = 'qb-collaborative-text-input'
    static shadow = 'open'

    constructor() {
        super();
        this.containerNode = cT('span', {
            contentEditable: true,
            beforeend: this.contentRoot,
            events: [this, 'beforeinput', 'compositionstart', 'compositionend'] //, 'compositionupdate']
        });
    }

    get value() {
        return this.containerNode.innerHTML;
    }

    set value(value) {
        if (typeof value === "string") {
            if (/^<ins/.test(value)) this.containerNode.innerHTML = value;
            // else {
            //     this.containerNode.textContent = "";
            //     this.#insNode(value);
            // }
        } else if (typeof value === "object") {

        }
    }

    get #selection() {
        return window.getSelection();
    }

    get #collapseToEnd() {
        if (this.#selection.rangeCount) this.#selection.collapseToEnd();
    }


    #changes = null

    #inserted(value) {
        if (!this.#changes) this.#changes = {};
        if (!this.#changes.inserted) this.#changes.inserted = [];
        this.#changes.inserted.push(value)
    }

    #deleted(value) {
        if (!this.#changes) this.#changes = {};
        if (!this.#changes.deleted) this.#changes.deleted = [];
        this.#changes.deleted.push(...value);
    }

    #pasted(value) {
        if (!this.#changes) this.#changes = {};
        if (!this.#changes.pasted) this.#changes.pasted = [];
        this.#changes.pasted.push(...value);
    }

    #range

    get #delNode() {
        if (!this.#range) this.#adjustRange;
        const nodes = this.#range.extractContents().children;
        if (nodes.length) this.#deleted([...nodes].map(n => n.id));
    }

    // #insertCompositionText(evt) {
    //     evt.preventDefault();
    //     console.log("Composing", evt);
    // }

    get #adjustRange() {
        let range;
        if (this.#selection.rangeCount) range = this.#selection.getRangeAt(0);
        else {
            range = new Range();
            range.selectNodeContents(this.containerNode);
            this.#selection.addRange(range);
        }
        if (!this.containerNode.contains(range.endContainer)) range.setEnd(this.containerNode, Math.max(0, this.containerNode.length - 1));
        if (!this.containerNode.contains(range.startContainer)) range.collapse();

        for (; range.endContainer !== this.containerNode;) {
            if (range.endOffset) range.setEndAfter(range.endContainer.parentNode);
            else range.setEndBefore(range.endContainer.parentNode);
        }
        for (; range.startContainer !== this.containerNode;) {
            if (range.startOffset) range.setStartAfter(range.startContainer.parentNode);
            else range.setStartBefore(range.startContainer.parentNode);
        }
        this.#range = range;
        return range;
    }

    #insNode(text) {
        const fragment = document.createDocumentFragment(),
            textIter = text[Symbol.iterator](),
            inserts = [];
        let key = textIter.next();
        while (!key.done) {
            const data = {id: randomId(), textContent: key.value},
                node = document.createElement(/\n/.test(key.value) ? 'br' : 'ins');
            inserts.push(data);
            fragment.append(Object.assign(node, data));
            key = textIter.next();
        }
        const range = this.#adjustRange;
        this.#delNode;
        range.insertNode(fragment);
        inserts.unshift(range.startContainer.children[range.startOffset - 1]?.id);
        inserts.push(range.endContainer.children[range.endOffset]?.id);
        this.#inserted(inserts);
        this.#collapseToEnd;
    }

    #insertText(evt) {
        evt.preventDefault();
        this.#insNode(evt.data);
    }

    #insertFromPaste(evt) {
        evt.preventDefault();
        let data, html;
        if (evt.dataTransfer.types.includes("text/html")) html = evt.dataTransfer.getData("text/html");
        if (evt.dataTransfer.types.includes("text/plain")) data = evt.dataTransfer.getData("text/plain");
        else if (!html) return;
        else {
            const frag = document.createElement("div");
            frag.innerHTML = html;
            data = frag.textContent.trim();
        }
        this.#pasted(html);
        if (data?.length) this.#insNode(data);
    }

    #insertParagraph(evt) {
        evt.preventDefault();
        this.#insNode("\n");
    }

    #deleteContentBackward(evt) {
        evt.preventDefault();
        if (this.#range.collapsed) {
            const node = this.containerNode.childNodes[this.#range.endOffset - 1];
            if (!node) return;
            this.#range.selectNode(node);
        }
        return this.#delNode;
    }

    handleEvent_beforeinput(evt) {
        switch (evt.inputType) {
            case "insertCompositionText":
                return evt.preventDefault();
        }

        this.#adjustRange;
        this.#changes = null;

        switch (evt.inputType) {
            case "insertText":
                this.#insertText(evt);
                break;


            case "insertFromDrop":
            case "insertFromPaste":
                this.#insertFromPaste(evt);
                break;

            case "insertLineBreak":
            case "insertParagraph":
                this.#insertParagraph(evt);
                break;
            case "deleteContentBackward":
            case "deleteByCut":
            case "deleteByDrag":
                this.#deleteContentBackward(evt);
                break;

            // case "deleteContent":
            // case "insertReplacementText":
            // case "insertOrderedList":
            // case "insertUnorderedList":
            // case "insertHorizontalRule":
            // case "insertFromYank":
            // case "insertFromPasteAsQuotation":
            // case "insertTranspose":
            // case "insertLink":
            // case "deleteWordBackward":
            // case "deleteWordForward":
            // case "deleteContentForward":
            // case "deleteSoftLineBackward":
            // case "deleteSoftLineForward":
            // case "deleteEntireSoftLine":
            // case "deleteHardLineBackward":
            // case "deleteHardLineForward":
            // case "historyUndo":
            // case "historyRedo":
            // case "formatBold":
            // case "formatItalic":
            // case "formatUnderline":
            // case "formatStrikeThrough":
            // case "formatSuperscript":
            // case "formatSubscript":
            // case "formatJustifyFull":
            // case "formatJustifyCenter":
            // case "formatJustifyRight":
            // case "formatJustifyLeft":
            // case "formatIndent":
            // case "formatOutdent":
            // case "formatRemove":
            // case "formatSetBlockTextDirection":
            // case "formatSetInlineTextDirection":
            // case "formatBackColor":
            // case "formatFontColor":
            // case "formatFontName":
            default:
                evt.preventDefault();
                console.log(evt);
                return;
        }
        if (this.#changes) this.dispatchEvent(new CustomEvent("changes", {bubbles: true, detail: this.#changes}));
        this.#changes = null;
    }

    handleEvent_compositionstart(evt) {
        const node = document.createElement('ins');
        node.id = randomId();
        const range = this.#adjustRange;
        this.#delNode;
        range.insertNode(node);
        range.selectNodeContents(node);
    }

    handleEvent_compositionend(evt) {
        const range = this.#adjustRange,
            children = range.startContainer.children;
        const {id, textContent} = children[range.startOffset - 1];
        this.#inserted([
            children[range.startOffset - 2]?.id,
            {id, textContent},
            children[range.endOffset]?.id
        ]);
        if (this.#changes) this.dispatchEvent(new CustomEvent("changes", {bubbles: true, detail: this.#changes}));
        this.#changes = null;
    }
}

QBCollaborativeTextinput.register;