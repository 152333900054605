/* Copyright 2022- Martin Kufner */
import {QbElement} from "./qb-element.js"
export class QBNotifications extends QbElement {
    constructor() {
        super();
        this.addEventListener('transitionend', e => {
            e.target.getBoundingClientRect().height || e.target.remove()
        });
    }



    #notify(type, title, ...more) {
        const notice = document.createElement("qb-notice");
        notice.setContent(title, ...more);
        notice.type = type

        return notice;
    }

    notify(type, title, ...more) {
        const notice = this.#notify(type, title, ...more),
            existingContainerContent = notice.containerContent;
        const found = [...this.querySelectorAll(`qb-notice[type="${type}"]`)].find(({containerContent})=>containerContent === existingContainerContent);
        if(found) {
            found.resetTimeout(notice.timeout);
            found.count += 1;
        }
        else this.append(notice);
        return found?.parentElement || notice;
    }
}
const template = document.createElement('template');
template.innerHTML = '<div><button type="button"></button><header></header></div>';

export class QBNotice extends QbElement {
    static shadow = 'closed'
    static template = template
    constructor() {
        super();
        this.timeout = 5000;
        this.initialized.then(()=>{
        if(!/^\s*$/.test(this.container.textContent) || /^\s*$/.test(this.textContent)) return;
            this.setContent(...this.childNodes);
            this.connectedCallback();
        });
    }

    get close() {
        this.style.height = '0';
    }

    set type(type) {
        this.setAttribute('type', type);
    }

    get container() {
        return this.contentRoot.firstElementChild;
    }

    get count() {
        return parseInt(this.container.dataset.count || 1);
    }
    set count(value) {
        this.container.dataset.count = value;
    }

    setContent(title, ...more) {
        this.style.height = '0px';
        const node = this.container,
            [button,header] = node.children;
        header.append(title);
        more.forEach(line => {
            switch(typeof line) {
                case "number":
                    return this.resetTimeout(line * 1000);
                case "string":
                    const n = document.createElement("div");
                    n.textContent = line;
                    line = n;
                    break;
                case "object":
                    break;
                default:
                    return;
            }
            line.onclick = ()=>{
                setTimeout(()=>this.resize,100);
                this.resetTimeout(Infinity);
            }
            node.append(line);
        });
        button.addEventListener('click', evt => this.close);
    }

    get containerContent() {
        return this.container.innerHTML
    }

    #timeout;
    resetTimeout(timeout) {
        if(timeout !== undefined) this.timeout = timeout;
        clearTimeout(this.#timeout);
        if(!this.timeout || this.timeout === Infinity) return;
        this.#timeout = setTimeout(() => this.close, this.timeout);
    }
    get resize() {
        const {height} = this.container.getBoundingClientRect();
        this.style.height = height + 'px';
    }

    connectedCallback() {
        this.resize;
        this.resetTimeout();
        // this.style.height = this.getBoundingClientRect().height + 'px';
    }
}

QBNotifications.register;
QBNotice.register;
["notice", "alert", "error"].forEach(t=>
    Object.defineProperty(window, t, {
        value(...values) {
            return document.querySelector('qb-notifications')?.notify(t, ...values) || alert(values.join("\n"));
        }
    })
)