/* Copyright 2022- Martin Kufner */
import {QbInputElement} from "./qb-element.js"
export class QbRatingElement extends QbInputElement {
    static nodeName = 'qb-rating'
    static shadow = 'open'
    constructor() {
        super();
        this.contentRoot.append(
            LABEL(
                this.range = cT('input type="range"', {events: [this, "change"]})
            ),
            this.node = cT('div part="symbols"')
        );
        this.$choices();
    }

    set step(value) { this.range.step = value; }
    get step() { return parseFloat(this.range.step); }

    get min() { return parseFloat(this.range.min); }

    get max() { return parseFloat(this.range.max); }

    handleEvent_change(evt) {
        const target = evt.currentTarget;
        this.rating = target.value;
        this.dispatch("change",{value: this.value, rating: this.rating} );
    }

    get value() {
        const rating = this.rating;
        if(rating === 0) return 0;
        if(rating < 0) return rating / this.min * -1;
        else return rating / this.max;
    }

    set value(value) {
        const {min, max} = this;
        value = Math.max(-1, Math.min(1, parseFloat(value)));
        if(max <= 0 || value < 0)
            this.rating = min * Math.min(0, value) * -1;
        else if(min >= 0 || value > 0)
            this.rating = max * Math.max(0, value);
        else if(value === 0)
            this.rating = 0;
    }

    get rating() { return parseFloat(this.range.value) || 0 }
    set rating(rating) {
        rating = parseFloat(rating) || 0;
        this.range.value = rating;
        const abs = Math.abs(rating),
            rounded = Math.ceil(abs) * Math.sign(rating),
            fraction = Math.abs(rounded - rating),
            [start, percent] = rating > 0 ? [0, 100 * (1 - fraction)] : [100, 100 * fraction];

        for(const n of this.node.children) {
            n.style.clipPath = "";
            if(n.style.order == rounded) {
                n.setAttribute("selected", "");
                if(rounded !== 0) n.style.clipPath = `polygon(${start}% 0%,${percent}% 0%,${percent}% 100%, ${start}% 100%)`;
            }
            else n.removeAttribute("selected");
        }
    }


    $choices(symbols) {
        //|⭕️|👍👍👍👍👍
        if(!symbols) symbols = '|×️|★★★★★️';
        const m = symbols.match(/^([^\|]*)\|([^\|]*)\|([^\|]*)$/);
        if(!m) throw "Format -----|0|+++++";
        const [neg, zero, pos] = Array.from(m).slice(1).map(s=>[...new Intl.Segmenter().segment(s)].map(x => x.segment));
        if(zero.length > 1 || (neg.length > 0 && pos.length > 0 && zero.length === 0)) throw "Format -----|0|+++++";
        let min, max;
        this.node.textContent = "";
        const fn = (symbol, order) => {
            min = isNaN(min) ? order : Math.min(min, order);
            max = isNaN(max) ? order : Math.max(max, order);
            let tag;
            if(order < 0) tag = "qb-rating-neg";
            else if(order > 0) tag = "qb-rating-pos";
            else tag = "qb-rating-zero";
            return cT(tag,{dataset: {symbol}, style: `order:${order}`});
        }
        this.node.append(
            ...neg.map((s,i) => fn(s,i-neg.length)),
            ...zero.map((s,i) => fn(s,0)),
            ...pos.reverse().map((s,i) => fn(s,pos.length-i))
        );
        const value = this.value;
        this.range.min = min;
        this.range.max = max;
        this.value = value;
    }


    static observedAttributes = ['choices'];

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue == newValue) return;
        switch(name) {
            case "choices":
                return this.$choices(newValue);
        }
    }

}
QbRatingElement.register;