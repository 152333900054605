/* Copyright 2022- Martin Kufner */
import {QbInputElement} from "./qb-element.js"

export class QBText extends QbInputElement {
    static observedAttributes = ["name", "readonly", "placeholder", "maxlength"];
    static shadow = 'open'

    constructor() {
        super();
        this.contentRoot.prepend(
            this.fieldset = FIELDSET({events: [this, "focusout", "focusin"]},
                this.contentNode = cT('div contentEditable="true"', {
                    attributes: {placeholder: "+ Note"},
                    events: [this, "input", "beforeinput", {click: "checkCaret", focusin: "input"}]
                }),
                this.searches = UL(),
                cT('button name="remove" title="Remove" tabIndex="-1"', {events: [this, "click"]}, "×️")
            )
        );
    }

    $readonly(value) {
        this.contentNode.contentEditable = value === null;
        this.timeNode.readonly = value !== null;
    }

    $placeholder(value) {
        if(value === null) this.contentNode.removeAttribute("placeholder");
        else this.contentNode.setAttribute("placeholder", value);
    }

    set value(value) {
        this.content = value;
        // this.input();
    }

    set content(value) {
        value = value.trim();
        const text = value.substr(0, this.maxLength || value.length);
        this.contentNode.textContent = '';
        if(!value) return;
        this.contentNode.append(...text.split(/\n+/).map(text=>DIV(text)));
        this.internals.setFormValue(this.contentNode.innerText);
    }

    get value() { return this.content; }
    get content() { return this.contentNode.innerText; }

    get hash() { return {content: this.content, type: this.itemtype}; }

    update(object) {
        if("id" in object) this.id = object.id;
        if("value" in object) this.content = object.value;
        if("content" in object) this.content = object.content;
    }

    textLength(text) {
        const maxLength = this.maxLength;
        const currentLength = this.currentLength + (text?.length || 0)
        this.contentNode.dataset.characters = isNaN(maxLength) ? currentLength : `${currentLength}/${maxLength}`;
    }

    handleEvent_beforeinput(evt) {
        const {maxLength, currentLength} = this;
        this.changed = true;
        let text = evt.data;
        const caret = Selection.Caret(this.contentNode);
        caret.focusMatch(/\?([a-z\u00E0-\u00FC&|]+)/i);
        if(caret.matched) caret.collapseToFocus;
        const {inputType, dataTransfer, data, isComposing} = evt,
            targetRanges = evt.getTargetRanges()[0];
        // console.log({inputType, dataTransfer, data, isComposing}, targetRanges);

        if(evt.dataTransfer) {
            text = evt.dataTransfer.getData("text/plain");
            if(!isNaN(maxLength)) text = text.substr(0, maxLength - currentLength);
            // caret.insert(text);
            document.execCommand("insertText", false, text);
        }
        else {
            switch(evt.inputType) {
                // case "insertParagraph":
                //     // document.execCommand("insertHTML", false, "<br/>");
                //     caret.insert(document.createElement("br"))
                //     break;
                default:
                    if(text === null || isNaN(maxLength) || currentLength + text.length < maxLength) return;
            }
        }
        evt.preventDefault();
        this.textLength(text)
    }

    handleEvent_checkCaret(evt) {
        const caret = Selection.Caret(this.contentNode);
        caret.focusMatch(/\?([a-z\u00E0-\u00FC&|]+)/i);
        if(caret.matched) {
            caret.select();
            this.dispatch("textsearch", { text: caret.text, type: this.itemtype });
        }
    }


    handleEvent_input(evt) {
        this.textLength();
        this.handleEvent_checkCaret(evt);
    }

    setTextSearch(text, texts) {
        const searches = this.searches;
        if(!texts?.length) return searches.textContent = "";
        if(searches.dataset.text !== text) searches.textContent = "";
        searches.dataset.text = text;
        const ids = texts.map(v => v.id);
        [...searches.children].forEach(n => ids.includes(n.itemid) || n.remove());
        texts.forEach(({id: itemid, lang, content, tsrank}) => {
            const node = (searches.querySelector(`[itemid="${itemid}"]`)) ||
                cT("li", {itemid, lang, beforeend: searches, events: [this, {mousedown: 'texts'}]}, content);
            const order = Math.floor((1 - tsrank) * 10000);
            if(node.style.order != order) node.style.order = order;
        });
    }

    handleEvent_texts(evt) {
        evt.preventDefault();
        const target = evt.currentTarget;
        const caret = Selection.Caret(this.contentNode);
        caret.delete;
        let text = target.innerText;
        if(!isNaN(this.maxLength)) text = text.substr(0, this.maxLength - this.currentLength - 1);
        caret.text = text;
        this.textLength();
    }

    handleEvent_remove(evt) {
        this.content = "";
        this.dispatch("change", this.hash);
        this.remove();
    }

    handleEvent_focusin(evt) {
        if(this.contentRoot.contains(evt.relatedTarget)) return;
        this.changed = false;
        Selection.Caret(this.contentNode);
        this.previousElementSibling?.matches('input[type="radio"]') && (this.previousElementSibling.checked = true);
    }


    handleEvent_focusout(evt) {
        // if (this.shadowRoot.contains(evt.relatedTarget)) return;
        this.searches.textContent = "";
        if(!this.changed) return;
        this.contentNode.innerText = this.contentNode.innerText.trim();
        if(!this.contentNode.innerText) this.contentNode.textContent = "";
        this.internals.setFormValue(this.formData);
        if(this.hasAttribute('required')) {
            this.internals.reportValidity(this.contentNode.innerText ? {} : { valueMissing: true }, "")
        }
        this.dispatch("change", this.hash);
    }

    remove() {
        const radio = this.previousElementSibling;
        if(radio?.matches('input[type="radio"]')) radio.remove();
        super.remove();
    }

    get currentLength() { return this.contentNode.innerText.trim().length }

    get maxLength() { return parseInt(this.getAttribute("maxlength")); }
    set maxLength(value) { return this.setAttribute("maxlength", value); }

    get placeholder() { return this.contentNode.getAttribute("placeholder"); }
    set placeholder(value) { return this.contentNode.setAttribute("placeholder", value); }
}

window.customElements.define('qb-text', QBText);